<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top" style="position: fixed;height: 50px;">
            <router-link to="/contract-list" style="margin-left: 20px;font-size: 32px;">
                    <b-icon icon="arrow-left" font-scale="1"></b-icon>
            </router-link>
            <span>Add Contract</span>
        </b-navbar>
        <div class="container-fluid p-2" style="margin-top: 16%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="col-md-12">
                <form v-on:submit.prevent="onSubmit">
                    <div class="form-group ">
                        <label for="inputDistributor">Distributor</label>
                        <select v-model="contractRequest.distributor" @change="validationRule()" class="form-control"
                            id="inputDistributor">
                            <option value="">Select Distributor</option>
                            <option :value="distributor.id" v-for="distributor in distributors">
                                {{distributor.name}}</option>
                        </select>
                        <small class="text-danger">{{validationData.distributor}}</small>
                    </div>
                    <div class="form-group">
                        <label for="inputLat">Contract Date</label>
                        <input type="date" v-model="contractRequest.contractDate" @blur="validationRule()"
                            class="form-control" id="inputLat">
                        <small class="text-danger">{{validationData.contractDate}}</small>
                    </div>
                    <div class="form-group">
                        <label for="inputLong">Exp Date</label>
                        <input type="date" v-model="contractRequest.expDate" @blur="validationRule()" class="form-control"
                            id="inputLong">
                        <small class="text-danger">{{validationData.expDate}}</small>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button type="submit" class="btn btn-primary mr-2" style="width: 100%;">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'location',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                distributors: [],
                contractRequest: {
                    id: "",
                    distributor: "",
                    contractDate: "",
                    expDate: ""
                },
                validationData: {
                    distributor: "",
                    contractDate: "",
                    expDate: ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                distributorListAction: 'distributorListAction',
                contractStoreAction: 'contractStoreAction'
            }),
            async distributorList() {
                this.isLoading = true;
                await this.distributorListAction().then(res => {
                    if (res.status == "success") {
                        this.distributors = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    id: this.contractRequest.id,
                    distributor: this.contractRequest.distributor,
                    contractDate: this.contractRequest.contractDate,
                    expDate: this.contractRequest.expDate

                }
                await this.contractStoreAction({
                    ...option
                }).then(res => {
                    this.$router.replace('/contract-list')
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
                let isValidation = []
                if (this.contractRequest.distributor == "") {
                    this.validationData.distributor = "The distributor field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.distributor = ""
                    isValidation.push(false)
                }
                if (this.contractRequest.contractDate == "") {
                    this.validationData.contractDate = "The contract date field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.contractDate = ""
                    isValidation.push(false)
                }
                if (this.contractRequest.expDate == "") {
                    this.validationData.expDate = "The exp date field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.expDate = ""
                    isValidation.push(false)
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.distributorList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>